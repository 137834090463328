import React from "react"
import { Helmet } from "react-helmet/es/Helmet"
import styled from "styled-components"

const SectionWidgetAgenda = ({ eventId, internalWebsiteBaseUrl }) => (
  <StyledWidgetAgenda>
    <div className="grid-container">
      <div className="grid-x grid-padding-x">
        <div className="small-12 cell">
          <Helmet>
            <script type="text/javascript">
              {`  
    (function() {
    var bz = document.createElement('script');
    bz.type = 'text/javascript';
    bz.async = true;
    bz.src = 'https://organizer.bizzabo.com/widgets/agenda/agenda.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(bz, s);
  })();
  `}
            </script>
          </Helmet>
          <div
            name="bizzabo-web-agenda"
            data-unique-name={eventId}
            data-internal-website-base-url={internalWebsiteBaseUrl}
          />
        </div>
      </div>
    </div>
  </StyledWidgetAgenda>
)

export default SectionWidgetAgenda
const StyledWidgetAgenda = styled.section`
  padding-top: 22px;
`
