import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"
import HeadingYrsPast from "../../images/svg/inline-svg/heading_yrs-past.svg"
import HeadingYrsPastMobile from "../../images/svg/inline-svg/heading_yrs-past_mobile.svg"

const SectionPastAgendas = ({ headingTag: HeadingTag = "p" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image1: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2014@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2015@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2016@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image4: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2017@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image5: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2018@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image6: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2019@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image7: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2020@2x.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image8: file(
            relativePath: {
              eq: "past-agendas/202002_Ascend_Website_Images_Agendas_2022 1.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image9: file(
            relativePath: {
              eq: "past-agendas/202403_Ascend_Website_Images_Agendas_2023.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 218) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const image1 = data.image1.childImageSharp.fluid
        const image2 = data.image2.childImageSharp.fluid
        const image3 = data.image3.childImageSharp.fluid
        const image4 = data.image4.childImageSharp.fluid
        const image5 = data.image5.childImageSharp.fluid
        const image6 = data.image6.childImageSharp.fluid
        const image7 = data.image7.childImageSharp.fluid
        const image8 = data.image8.childImageSharp.fluid
        const image9 = data.image9.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <StyledVerticalSectionTitle>
                  <VerticalSectionTitle
                    svg={<HeadingYrsPast />}
                    mobileSvg={<HeadingYrsPastMobile />}
                  />
                </StyledVerticalSectionTitle>

                <div className="small-12 large-2 cell">
                  <SectionHeading>View Our Past Agendas</SectionHeading>
                </div>
                <div className="small-12 large-9 cell agenda-covers">
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/661541d92543c026703d2ce4_202403_Ascend_2023Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image9} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d4252cf058e9ad91ac6d_202002_Ascend_2022Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image8} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d46d54cb2f3cd5390dc7_202002_Ascend_2020Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image7} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d4a566d6acced4bfbb40_202002_Ascend_2019Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image6} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d4d19d95e948bba2d627_202002_Ascend_2018Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image5} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d509aff4ad96e1024c09_202002_Ascend_2017Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image4} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d52cf675358d75b380c5_202002_Ascend_2016Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image3} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d55473fc956b9213632d_202002_Ascend_2015Program_v1.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image2} />
                    </a>
                  </div>
                  <div className="agenda-cover">
                    <a
                      href="https://uploads-ssl.webflow.com/6480cd9bff9f9835689b10c3/64f1d57dbd89d831972e89b1_Ascend_2014_Agenda.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img fluid={image1} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionPastAgendas

const StyledSection = styled.section`
  padding: 50px 0;

  .agenda-covers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .agenda-cover {
    width: 218px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding: 0.5em;
    }
  }
`
const SectionHeading = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
`
const StyledVerticalSectionTitle = styled.div``
