import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Helmet } from "react-helmet/es/Helmet"

const SectionFeaturedSpeakers = ({
  headingTag: HeadingTag = "p",
  eventId,
  bizzaboUniqueName,
  internalWebsiteBaseUrl,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            relativePath: { eq: "background/bg-brush-accent-12.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={() => {
        return (
          <>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="small-12 cell text-center">
                  <Helmet>
                    <script type="text/javascript">
                      {`
                     (function() {
                    !function() {
                    var e = document.createElement("script");
                    window.bz_widget_env = "https://events.bizzabo.com", e.type = "text/javascript", e.async = !0, e.src = "https://organizer.bizzabo.com/widgets/sections/sections.js";
                    var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();  })();`}
                    </script>
                  </Helmet>
                  <div
                    className="bizzabo-sections-widget"
                    data-unique-name={bizzaboUniqueName}
                    data-tab-id={3282000}
                    data-internal-website-base-url={internalWebsiteBaseUrl}
                  />
                </div>
              </div>
            </div>

            <div className="grid-container">
              <div className="grid-x grid-padding-x"></div>
            </div>
          </>
        )
      }}
    />
  )
}

export default SectionFeaturedSpeakers
